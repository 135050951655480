import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';

const Commercial = (props) => {

	const cd = props.commercial;
	const setIsLoading = props.setIsLoading;
	console.log('ComDetails', cd);
	const { token, setToken } = useToken();
	const [legalForms, setLegalForms] = useState([]);

	useEffect(() => {
		async function getData() {
			const pars = { 
				"function":"legal_forms", 
				"token":token, 
			}
			setIsLoading(true);
			const ApiGet = await ApiCall(pars);
			setIsLoading(false);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				console.log('LegalForms:', ApiData);
				setLegalForms(ApiData);
			}
		}
		getData();
	}, []);

	return (
		<Box 
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<div>
				<br/>
				<p>იურიდიული პირის დეტალები</p>
				<br/>
				<form>
					<div>
						<TextField
							label="საიდინტიფიკაციო კოდი"
							id="taxid"
							defaultValue={cd.cid}
						/>
						<TextField
							label="სამართლებრივი ფორმა"
							select
							id="legal_form"
							value={cd.legal_form}
							style={{width:450}}
							fullWidth
						>
							{legalForms.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.display_name}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div>
						<TextField
							label="სახელწოდება"
							id="company_name"
							style={{width:680}}
							defaultValue={cd.company_name}
						/>
					</div>
					<div>
						<TextField
							label="იურიდიული მისამართი"
							id="address"
							style={{width:680}}
							defaultValue={cd.address}
						/>
					</div>
					<div>
						<TextField
							label="ფაქტობრივი მისამართი"
							id="fact_address"
							style={{width:680}}
							defaultValue={cd.address}
						/>
					</div>
					<div>
						<TextField
							label="ტელეფონი"
							id="phone"
							defaultValue={cd.phone}
						/>
						<TextField
							label="მობილური"
							id="cellphone"
							defaultValue={cd.cellphone}
						/>
					</div>
					<div>
						<TextField
							label="წარმომადგენლის თანამდებობა"
							id="representor_position"
						/>
						<TextField
							label="წარმომადგენლის პ/ნ"
							id="pid"
							defaultValue={cd.pid}
						/>
						<TextField
							label="სახელი"
							id="first_name"
							defaultValue={cd.first_name}
						/>
						<TextField
							label="გვარი"
							id="last_name"
							defaultValue={cd.last_name}
						/>
					</div>
					&nbsp;&nbsp;
					<Button
						type="submit"
						variant="contained"
						color="primary"
						endIcon={<HighlightOffIcon />}
					>
						ცვლილებების უარყოფა
					</Button>&nbsp;&nbsp;
					<Button
						type="submit"
						variant="contained"
						color="primary"
						endIcon={<PersonAddAlt1Icon />}
					>
						ცვლილებების შენახვა
					</Button>
				</form>
			</div>
		</Box>
	);
};

export default Commercial;