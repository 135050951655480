import React, { useState, useEffect } from 'react';
import './Registry.css';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import useRetailer from '../../App/useRetailer';
import { useNavigate } from 'react-router-dom';

const ConfirmDialog = (props) => {

	const customerDetails = props.customerDetails;
	const setCustomerDetails = props.setCustomerDetails;
	const setIsLoading = props.setIsLoading;
	const openDialog = props.openDialog;
	const setOpenDialog = props.setOpenDialog;
	const setCustomer = props.setCustomer;
	const cd = props.cd;

	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();
	const [waitCode, setWaitCode] = useState(false);
	const [uuCode, setUUCode] = useState();
	const [codeMethod, setCodeMethod] = useState();
	const [codeWaitRemain, setCodeWaitRemain] = useState('გამოგზავნა');
	const [errConfirmCode, setErrConfirmCode] = useState(true);
	const [errCodeMethod, setErrCodeMethod] = useState(true);
	const [dialogContext, setDialogContext] = useState('აირჩიეთ შემოწმების მეთოდი');

	const navigate = useNavigate();

	async function requestCode() {
		const pars = {
			"function": "send_verification_code",
			"token": token,
			"pars": { "method": codeMethod, "to_number": cd.cellphone, "to_email": cd.email, "retailer": retailer },
		}
		setIsLoading(true);
		const ApiGet = await ApiCall(pars);
		setIsLoading(false);
		if (ApiGet['success'] === 'success') {
			const uucode = ApiGet.uucode;
			setUUCode(uucode);
			setDialogContext('კოდი გამოგზავნილია, შეამოწმეთ');
			return (uucode);
		} else {
			setDialogContext('შეფერხება, სცადეთ თავიდან');
		}
	}

	async function saveCustomerData(pars) {
		const json = {
			'function': 'customer_ind_add',
			'token': token,
			'pars': pars
		}
		setIsLoading(true);
		const ApiGet = await ApiCall(json);
		setIsLoading(false);
		if (ApiGet['success'] === 'success') {
			setOpenDialog(false);
			setCustomer(ApiGet.account_id);
			navigate('/customer');
		} else {
			setDialogContext(ApiGet.reason);
		}
		return ApiGet;
	}

	useEffect(() => {
		let timer = null;
		if (waitCode) {
			timer = setInterval(() => {
				setCodeWaitRemain(seconds => seconds - 1);
			}, 1000);
		}
		return () => { clearInterval(timer); };
	});

	useEffect(() => {
		if (codeWaitRemain < 1) {
			setWaitCode(false);
			setCodeWaitRemain('განმეორება');
		}
	}, [codeWaitRemain]);

	const handleRequestCode = () => {
		if (codeMethod) {
			setCodeWaitRemain(60);
			setWaitCode(true);
			requestCode();
		} else {
			setDialogContext('არ გაქვთ არჩეული მეთოდი!');
		}
	}

	const handleCodeMethod = (e) => {
		setCodeMethod(e.target.value);
		setErrCodeMethod(false);
		setDialogContext('მოითხოვეთ კოდი');
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleSubmitDialog = (e) => {
		e.preventDefault();
		let isReady = true;
		const et = e.target;

		if (et.receivedCode.value.length !== 4) {
			setErrConfirmCode(true);
			isReady = false;
		} else setErrConfirmCode(false);

		if (isReady) {
			const ncd = {
				...cd,
				"confirmCode": et.receivedCode.value,
				"uuCode": uuCode,
			}
			setCustomerDetails(ncd);
			saveCustomerData(ncd);
		}
	};

	return (
		<Dialog open={openDialog} onClose={handleCloseDialog}
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
		>
			<form onSubmit={handleSubmitDialog}>
				<DialogTitle>შემოწმების ეტაპი</DialogTitle>
				<DialogContent>
					<Box>
						<DialogContentText>
							<h4 className="red">{dialogContext}</h4>
						</DialogContentText>
						<fieldset>
							<div>
								<RadioGroup
									name='codeMethod'
									id='codeMethod'
									error={errCodeMethod}
									onClick={handleCodeMethod}
								>
									<FormControlLabel
										value="sms"
										control={<Radio />}
										label={'SMS ნომერზე: ' + cd.cellphone}
										disabled={waitCode}
									/>
									{/* <TextField
										inputProps={
											{ readOnly: true, }
										}
										id="cellphone"
										label="მობილური ტელეფონი"
										variant="outlined"
										style={{ width: 200 }}
										value={customerDetails.cellphone}
									/> */}
									<FormControlLabel
										value="email"
										control={<Radio />}
										label={'E-Mail მისამართზე: ' + cd.email}
										disabled={waitCode}
									/>
									{/* <TextField
										inputProps={
											{ readOnly: true, }
										}
										id="email"
										label="ელ.ფოსტა"
										variant="outlined"
										style={{ width: 264 }}
										value={customerDetails.email}
									/> */}
								</RadioGroup>
							</div>
							<div>
								<Grid container direction="row" spacing="8">
									{/* <Grid item>
										<TextField
											style={{ width: 135 }}
											label="კოდის მიღება"
											select
											id="codeMethod"
											name="codeMethod"
											defaultValue={codeMethod}
											validate
											onChange={handleCodeMethod}
										>
											<MenuItem key='email' value='email'>
												ელ.ფოსტა
											</MenuItem>
											<MenuItem key="sms" value='sms'>
												SMS
											</MenuItem>
										</TextField>
									</Grid> */}
									<Grid item>
										<Button
											className='buttonMid'
											style={{ width: 130 }}
											variant="contained"
											onClick={handleRequestCode}
											disabled={waitCode}
										>
											{codeWaitRemain > 0 ? 'დაელოდეთ ' + codeWaitRemain : codeWaitRemain}
										</Button>
									</Grid>
									<Grid item>
										<TextField
											type='number'
											id="receivedCode"
											label="მიღებული კოდი"
											variant="outlined"
											style={{ width: 185 }}
											error={errConfirmCode}
											inputProps={{ maxLength: 4 }}
										/>
									</Grid>
								</Grid>
							</div>
						</fieldset>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseDialog}>უარყოფა</Button>
					<Button variant="contained" type="submit">დასრულება</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default ConfirmDialog;