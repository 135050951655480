import React, { useState, useEffect } from 'react';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import useRetailer from '../../App/useRetailer';
import CustomerCommercial from './CustomerCommercial';
import CustomerIndividual from './CustomerIndividual';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CustomerProducts from './CustomerProducts';
import CustomerUsers from './CustomerUsers';

let retcode = '';

const Customer = (props) => {

	const { token, setToken } = useToken();
	const [isLoaded, setIsLoaded] = useState(false);
	const [detailsTab, setDetailsTab] = useState("");
	const [customerDetails, setCustomerDetails] = useState([]);
	const [tabId, setTabId] = useState('1');
	const { retailer, setRetailer } = useRetailer();

	useEffect(() => {
		async function getAccountDetails() {
			// console.log('token:', token);
			const pars = {
				"function": "customer_details",
				"token": token,
				"pars": { "account_id": props.customer, "retailer": retailer },
			}
			const ApiGet = await ApiCall(pars);
			//   setIsLoaded(true);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				console.log(' ================== ApiData:', ApiData);
				if (ApiData.local.is_commercial) {
					console.log("pass A");
					retcode = <CustomerCommercial customerDetails={ApiData} />;
					console.log("pass AF");
				} else {
					console.log("pass B");
					retcode = <CustomerIndividual customerDetails={ApiData} />;
					console.log("pass BF");
				}
				// console.log("ApiData:", ApiData);
				setCustomerDetails(ApiData);
				setIsLoaded(true);
			}
		}
		// setIsLoaded(false);
		getAccountDetails();
	}, []);

	const handleChangeT = (event, newValue) => {
		console.log("setTabId=", newValue);
		setTabId(newValue);
	};

	console.log('Call from customer:', props.customer);
	return (
		<div>
			{!isLoaded && <p>იტვირთება მონაცემები...</p>}
			{isLoaded && (
				<TabContext value={tabId}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChangeT} aria-label="lab API tabs example">
							<Tab icon={<DisplaySettingsIcon />} label="დეტალები" value="1" />
							<Tab icon={<ShoppingBasketIcon />} label="პროდუქტები" value="2" />
							<Tab icon={<AccessibilityIcon />} label="მომხმარებლები" value="3" />
						</TabList>
					</Box>
					<TabPanel sx={{ padding: 0 }} value="1">{retcode}</TabPanel>
					<TabPanel sx={{ padding: 0 }} value="2"><CustomerProducts customerDetails={customerDetails} customer={props.customer} /></TabPanel>
					<TabPanel sx={{ padding: 0 }} value="3"><CustomerUsers customerDetails={customerDetails} customer={props.customer} /></TabPanel>
				</TabContext>
			)}
		</div>
	)
}

export default Customer;