import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import useToken from '../App/useToken';

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import { Link, useNavigate } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BackpackIcon from '@mui/icons-material/Backpack';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import RetailerSelect from '../ui/RetailerSelect';
import Collapse from '@mui/material/Collapse';

// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://telecom1.ge/">
				ტელეკომ1
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);

const mdTheme = createTheme();

export default function DrawerMenu(props) {

	const operatorDetails = props.operatorDetails;
	const operator_info = operatorDetails.operator_info;
	const individual_info = operatorDetails.individual_info;
	const accounts = operatorDetails.accounts;

	const [open, setOpen] = useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};
	const navigate = useNavigate();
	const { token, setToken } = useToken();

	const myNav = (lnk) => {
		navigate(lnk);
	}
	const [customersOpen, setCustomersOpen] = useState(false);

	const handleCustomersOpen = () => {
		setCustomersOpen(!customersOpen);
	}

	async function handleLogout() {
		setToken('');
		sessionStorage.removeItem('token');
		navigate("/");
		window.location.reload(false);
		console.log("new toktoken:", token);
	}

	const mainListItems = (
		<React.Fragment>
			<ListItemButton
				// onClick={() => myNav('customers')}
				onClick={handleCustomersOpen}
			>
				<ListItemIcon>
					<PeopleIcon />
				</ListItemIcon>
				<ListItemText primary="აბონენტები" />
				{customersOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={customersOpen} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<ListItemButton
						onClick={() => myNav('customers-individuals')}
					>
						<ListItemIcon>
							<PersonIcon />
						</ListItemIcon>
						<ListItemText primary="ფიზიკური პირები" />
					</ListItemButton>
					<ListItemButton
						onClick={() => myNav('customers-commercials')}
					>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText primary="იურიდიული პირები" />
					</ListItemButton>
				</List>
			</Collapse>
			<ListItemButton
				onClick={() => myNav('products')}
			>
				<ListItemIcon>
					<ShoppingCartIcon />
				</ListItemIcon>
				<ListItemText primary="პროდუქტები" />
			</ListItemButton>
			<ListItemButton
				onClick={() => myNav('packages')}
			>
				<ListItemIcon>
					<BackpackIcon />
				</ListItemIcon>
				<ListItemText primary="პაკეტები" />
			</ListItemButton>
			<ListItemButton
				onClick={() => myNav('categories')}
			>
				<ListItemIcon>
					<CategoryIcon />
				</ListItemIcon>
				<ListItemText primary="კატეგორიები" />
			</ListItemButton>
		</React.Fragment>
	);

	const secondaryListItems = (
		<React.Fragment>
			<ListSubheader component="div" inset>
				{/* დამატებით */}
			</ListSubheader>
			<ListItemButton>
				<ListItemIcon>
					<AccountBoxIcon />
				</ListItemIcon>
				<ListItemText primary={individual_info.first_name + ' ' + individual_info.last_name} />
			</ListItemButton>
			<ListItemButton>
				<ListItemIcon>
					<SettingsIcon />
				</ListItemIcon>
				<ListItemText primary="პარამეტრები" />
			</ListItemButton>
			<ListItemButton
				onClick={() => handleLogout()}
			>
				<ListItemIcon>
					<LogoutIcon />
				</ListItemIcon>
				<ListItemText primary="გასვლა" />
			</ListItemButton>
		</React.Fragment>
	);

	const container = props.container;

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position="absolute" open={open}>
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed
						}}
					>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{ flexGrow: 1 }}
						>
							აბონენტების მართვის სისტემა
						</Typography>
						<IconButton color="inherit">
							{/* <Badge badgeContent={4} color="secondary">
								<NotificationsIcon />
							</Badge> */}
							<RetailerSelect ttl='არჩეული კომპანია' />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" open={open}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							px: [1],
						}}
					>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<List component="nav">
						{mainListItems}
						<Divider sx={{ my: 1 }} />
						{secondaryListItems}
					</List>
				</Drawer>
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === 'light'
								? theme.palette.grey[100]
								: theme.palette.grey[900],
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<Toolbar />
					<Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
						{container}
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	);
}
