import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ka } from "date-fns/locale";
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import InputMask from "react-input-mask";
import useRetailer from '../../App/useRetailer';

const RegIndStep1 = (props) => {

	console.log("Enter to RegIndStep1");

	const navigate = useNavigate();
	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();
	const setIsLoading = props.setIsLoading;

	const setRegisterStep = props.setRegisterStep;
	const setCustomerDetails = props.setCustomerDetails;
	const setCancel = props.setCancel;
	const pid = props.pid;
	const setPid = props.setPid;
	const setCustomerState = props.setCustomerState;
	const customerState = props.customerState;

	const [openStep1, setOpenStep1] = React.useState(true);
	const [id, setId] = React.useState([]);
	const [inpErr, setInpErr] = React.useState(false);

	async function getData(pid) {
		// console.log('token:', token);
		const pars = {
			"function": "individual_customer_details",
			"token": token,
			"pars": { "pid": pid, "retailer": retailer },
		}
		// setIsLoading(true);
		const ApiGet = await ApiCall(pars);
		// setIsLoading(false);
		// console.log('ApiGet:', ApiGet);
		if (ApiGet['success'] == 'success') {
			// console.log('ApiGet is OK');
			const ApiData = ApiGet.data;
			// console.log('ApiData:', ApiData);
			return (ApiData);
		} else {
			// navigate("/login");
		}
	}

	// const handleClickOpenStep1 = () => {
	// 	setOpenStep1(true);
	// };

	const handleCloseStep1 = () => {
		setOpenStep1(false);
		// navigate("/customers/individuals");
		setCancel(true);
	};

	const handleSubmitStep1 = async () => {
		const ipid = document.getElementById('pid').value;
		if (ipid > 10000000000 && ipid < 99999999999) {
			setOpenStep1(false);
			const individualDetails = await getData(ipid);
			setId(individualDetails);
			setCustomerDetails(individualDetails);
			setPid(ipid);
			// setOpenStep2(true);
			setRegisterStep(2);
			if (individualDetails && individualDetails.identify) setCustomerState(2)
			if (individualDetails && !individualDetails.identify) setCustomerState(1)
			if (!individualDetails) setCustomerState(0)
		}
	};

	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSubmitStep1();
		}
	}

	const handlePidCheck = e => {
		e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 11);
		if (e.target.value > 10000000000 && e.target.value < 99999999999) {
			setInpErr(false);
			e.target.style.color = 'black';
		} else {
			setInpErr(true);
			e.target.style.color = 'red';
		}
	}

	return (
		<div>
			<Dialog open={openStep1} onClose={handleCloseStep1}>
				<DialogTitle>ახალი აბონენტი</DialogTitle>
				<DialogContent>
					<DialogContentText>
					</DialogContentText>
					<TextField
						id="pid"
						label="პირადი ნომერი"
						style={{ width: 180 }}
						autoFocus
						required
						margin="dense"
						fullWidth
						variant="standard"
						onKeyPress={handleKeyPress}
						defaultValue={pid}
						type="number"
						error={inpErr}
						inputProps={{ maxLength: 11 }}
						onInput={handlePidCheck}
					>
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseStep1}>უარყოფა</Button>
					<Button variant="contained" onClick={handleSubmitStep1}>გაგრძელება</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RegIndStep1;