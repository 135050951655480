import React, { useState, useEffect } from 'react';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import useRetailer from '../../App/useRetailer';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

const Products = (props) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [packages, setPackages] = useState([]);
  const { token, setToken } = useToken();
  const { retailer, setRetailer } = useRetailer();
  const setIsLoading = props.setIsLoading;

  useEffect(() => {

    async function getData(fn) {
      // // console.log('token:', token);
      // const para = {
      //   "account_id":retailer
      // }
      setIsLoading(true);
      const pars = {
        "function": fn,
        "token": token,
        "pars": { "retailer": retailer },
      }
      const ApiGet = await ApiCall(pars);
      setIsLoading(false);
      // console.log('ApiGet:', ApiGet);
      if (ApiGet['success'] == 'success') {
        // console.log('ApiGet is OK');
        const ApiData = ApiGet.data;
        // console.log('ApiData:', ApiData);
        return ApiData;
      }
    }

    const getProducts = async () => {
      const prods = await getData('products_list');
      setProducts(prods);
    }

    const getCategories = async () => {
      const cats = await getData('categories_list');
      setCategories(cats);
    }

    const getPackages = async () => {
      const packs = await getData('packages_list');
      setPackages(packs);
    }

    getProducts();
  }, [])

  // useEffect(() => {
  //   console.log('products: ', products);
  // }, [products]);

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      hide: true,
    },
    {
      field: 'cat_display_name',
      headerName: 'კატეგორია',
      width: 180,
      editable: false,
      // type: 'number',
    },
    {
      field: 'display_name',
      headerName: 'სახელი',
      width: 400,
    },
    {
      field: 'base_price',
      headerName: 'ღირებულება',
      width: 110,
    },
    {
      field: 'base_install_fee',
      headerName: 'ინსტალაციის ღირებულება',
      width: 200,
    },
    {
      field: 'minimal_deposit',
      headerName: 'მინიმალური დეპოზიტი',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'სტატუსი',
      description: 'აქტიური ან არააქტიური',
      width: 100,
      type: 'boolean'
    },
    {
      field: 'action',
      headerName: ' ',
      width: 120,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
            onClick={() => {
              console.log("Row Parameters: ", params);
              console.log("Row Parameters: account_id:", params.row.account_id);
              props.setCustomer(params.row.account_id);
              // navigate("/customer");
            }}
          >
            დეტალები
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <div>
      <h2>პროდუქტების ჩამონათვალი</h2>
      <Box sx={{ height: 502, width: 1 }}>
        <DataGrid
          rowHeight={35}
          rows={products}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: QuickSearchToolbar }}
        />
      </Box>
    </div>
  );
};

export default Products;