import React, { useState, useEffect } from 'react';
// import ApiCall from '../ApiCall';
import useToken from '../../App/useToken';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ApiCall from '../../ApiCall';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { ka } from "date-fns/locale";

const Individual = (props) => {

	const { token, setToken } = useToken();
	const setIsLoading = props.setIsLoading;
	const id = props.individual;
	const [value, setValue] = React.useState(new Date());

	const gender = [
		{
			"id":"m", 
			"name":"კაცი"
		},
		{
			"id":"f", 
			"name":"ქალი"
		}
	];

	return (
		<div>
			<Box 
				component="form"
				sx={{
					'& .MuiTextField-root': { m: 1, width: '25ch' },
				}}
				noValidate
				autoComplete="off"
			>
				<div>
					<br/>
					<p>ფიზიკური პირის დეტალები</p>
					<br/>
					<form>
						<div>
							<TextField
								label="საიდინტიფიკაციო კოდი"
								id="taxid"
								defaultValue={id.pid}
							/>
							<TextField
								label="სახელი"
								id="first_name"
								defaultValue={id.first_name}
							/>
							<TextField
								label="გვარი"
								id="last_name"
								defaultValue={id.last_name}
							/>
							<TextField
								label="სქესი"
								select
								id="gender"
								value={id.gender}
							>
								{gender.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</TextField>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={ka}>
								<Stack spacing={3}>
									<DatePicker
										label="დაბადების თარიღი"
										minDate={new Date("1920-01-01")}
										maxDate={new Date()}
										value={id.birthday}
										inputFormat="yyyy-MM-dd"
										mask="____-__-__"
										onChange={(newValue) => {
											setValue(newValue);
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Stack>
							</LocalizationProvider>
							<TextField
								label="დაბადების თარიღი"
								id="company"
								defaultValue={id.birthday}
							/>
						</div>
						<div>
							<TextField
								label="მისამართი"
								id="login"
								defaultValue={id.address}
							/>
							<TextField
								label="ტელეფონი"
								id="phone"
								defaultValue={id.phone}
							/>
						</div>
						&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonRemoveAlt1Icon />}
						>
							კლიენტის გაუქმება
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<HighlightOffIcon />}
						>
							ცვლილებების უარყოფა
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonAddAlt1Icon />}
						>
							ცვლილებების შენახვა
						</Button>
					</form>
				</div>
			</Box>
		</div>
	)
}

export default Individual;