import React from 'react';

const CustomerUsers = () => {
	return (
		<div>
			
		</div>
	);
};

export default CustomerUsers;