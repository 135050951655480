import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useRetailer from '../../App/useRetailer';

const Customers = (props) => {

	const [rows, setRows] = useState([]);
	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();
	const navigate = useNavigate();

	useEffect(() => {
		async function getAccounts() {
			// // console.log('token:', token);
			// const para = {
			//   "account_id":retailer
			// }
			const pars = {
				"function": "customers_list",
				"token": token,
				"pars": { "retailer": retailer },
			}
			const ApiGet = await ApiCall(pars);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] === 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				// console.log('ApiData:', ApiData);
				setRows(ApiData);
			}
		}
		getAccounts();
	}, [])

	function QuickSearchToolbar() {
		return (
			<Box
				sx={{
					p: 0.5,
					pb: 0,
				}}
			>
				<GridToolbarQuickFilter />
			</Box>
		);
	}

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 90,
			hide: true,
		},
		{
			field: 'identify',
			headerName: 'პ/ნ / ს/კ',
			width: 120,
			editable: false,
			// type: 'number',
		},
		{
			field: 'first_name',
			headerName: 'სახელი',
			width: 150,
		},
		{
			field: 'last_name',
			headerName: 'გვარი',
			width: 150,
		},
		{
			field: 'company_name',
			headerName: 'ორგანიზაცია',
			width: 200,
		},
		{
			field: 'status',
			headerName: 'სტატუსი',
			description: 'აქტიური ან არააქტიური',
			width: 100,
			type: 'boolean'
		},
		{
			field: 'action',
			headerName: ' ',
			width: 120,
			renderCell: (params) => (
				<strong>
					<Button
						variant="contained"
						color="primary"
						size="small"
						style={{ marginLeft: 5 }}
						onClick={() => {
							console.log("Row Parameters: ", params);
							console.log("Row Parameters: account_id:", params.row.account_id);
							props.setCustomer(params.row.account_id);
							navigate("/customer");
						}}
					>
						დეტალები
					</Button>
				</strong>
			),
		},
	];

	return (
		<div>
			{/* style={{ position: 'relative' }}> */}
			{/* <div style={{ height: 650, width: '100%', position: 'absolute' }}> */}
			<h3>აბონენტები</h3>
			<Button
				variant="outlined"
				startIcon={<PersonAddIcon />}
				onClick={() => navigate("/register-customerindividuals")}
			>
				ფიზიკური პირის რეგისტრაცია
			</Button>&nbsp;&nbsp;
			<Button
				variant="outlined"
				startIcon={<AddBusinessIcon />}
				onClick={() => navigate("/register-customercommercials")}
			>
				იურიდიული პირის რეგისტრაცია
			</Button>&nbsp;&nbsp;
			<Button
				variant="outlined"
				startIcon={<AccountBalanceIcon />}
				onClick={() => navigate("/registry/individualsaddbypid")}
			>
				მომხმარებლის რეგისტრაცია
			</Button>
			<Box sx={{ height: 502, width: 1 }}>
				<DataGrid
					rowHeight={35}
					rows={rows}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					// checkboxSelection
					disableSelectionOnClick
					components={{ Toolbar: QuickSearchToolbar }}
				/>
			</Box>
			{/* </div> */}
		</div>
	);
}

export default Customers;