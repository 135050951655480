import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './Registry.css';
import useToken from '../../App/useToken';
import useRetailer from '../../App/useRetailer';
import ImageUpload from './ImageUpload';
import ImageCapture from './ImageCapture';

const RegIndStep3 = (props) => {

	let id = props.customerDetails;
	const setRegisterStep = props.setRegisterStep;
	const setCustomerDetails = props.setCustomerDetails;
	const setCancel = props.setCancel;
	const setIsLoading = props.setIsLoading;
	const setImageFiles = props.setImageFiles;
	const imageFiles = props.imageFiles;

	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();
	const [openStep, setOpenStep] = useState(true);
	const [uploadMethod, setUploadMethod] = useState('uploadFile');

	console.log("Enter to RegIndStep3");

	const handleBackStep = () => {
		setOpenStep(false);
		setRegisterStep(2);
	}

	const handleCloseStep = () => {
		setOpenStep(false);
		setCancel(true);
	};

	const handleSubmitStep = (e) => {
		e.preventDefault();
		let isReady = true;
		const et = e.target;

		setOpenStep(false);
		setRegisterStep(4);
	};

	const handleUploadMethod = (e) => {
		console.log('current method=', e.target.value);
		if (e.target.value) setUploadMethod(e.target.value);
	}

	return (
		<Dialog open={openStep} onClose={handleCloseStep}
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
		>
			<form onSubmit={handleSubmitStep}>
				<DialogTitle>ახალი აბონენტი</DialogTitle>
				<DialogContent>
					<Box>
						<DialogContentText>
							<h4 className="red">პირადობის ასლი</h4>
						</DialogContentText>
						<fieldset>
							<div>
								<RadioGroup
									onClick={handleUploadMethod}

								>
									<FormControlLabel
										value='uploadFile'
										control={<Radio />}
										label='ფაილის ატვირთვა'
									/>
									<FormControlLabel
										value='captureImage'
										control={<Radio />}
										label='სურათის გადაღება'
									// disabled
									/>
								</RadioGroup>
							</div>
						</fieldset>
						<div className="imageFrame">
							{uploadMethod === 'uploadFile' && <ImageUpload setImageFiles={setImageFiles} imageFiles={imageFiles} />}
							{uploadMethod === 'captureImage' && <ImageCapture />}
						</div>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleBackStep}>უკან</Button>
					<Button variant="contained" type="submit">გაგრძელება</Button>
				</DialogActions>
			</form>
		</Dialog >
	);
};

export default RegIndStep3;