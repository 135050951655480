import React, { useCallback, useState, useEffect } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import Button from '@mui/material/Button';
import AddCardIcon from '@mui/icons-material/AddCard';

const ImageUpload = (props) => {

	const setImageFiles = props.setImageFiles;
	const imageFiles = props.imageFiles;

	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps
	} = useDropzone({
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'application/pdf': [],
		},
		maxFiles: 2
	});

	const acceptedFileItems = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	useEffect(() => {
		console.log("acceptedFiles", acceptedFiles);
		console.log('acceptedFileItems', acceptedFileItems);
		setImageFiles(acceptedFiles);
	}, [acceptedFiles]);

	return (
		<section className="container">
			<div {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<p>დააჭირე ან ჩააგდე აქ</p>
				<em>მისაღებია *.jpeg, *.png, *.pdf</em>
			</div>
			<aside>
				<h4>მიღებული ფაილები</h4>
				<ul>{acceptedFileItems}</ul>
				<h4>უარყოფილი ფაილები</h4>
				<ul>{fileRejectionItems}</ul>
			</aside>
		</section>
	);
};

export default ImageUpload;