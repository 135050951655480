import React, { useState, useEffect } from 'react';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import useRetailer from '../../App/useRetailer';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

const CustomerProducts = (props) => {
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [packages, setPackages] = useState([]);
	const [customerProducts, setCustomerProducts] = useState([]);
	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();

	useEffect(() => {
		async function getData(prs) {
			// // console.log('token:', token);
			// const para = {
			//   "account_id":retailer
			// }
			const pars = { 
				...prs,
				"token":token, 
			}

			const ApiGet = await ApiCall(pars);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				// console.log('ApiData:', ApiData);
				return ApiData;
			}
		}
	
		const getProducts = async () => {
		  const prods = await getData({'function':'products_list'});
		  setProducts(prods);
		}
	
		const getCategories = async () => {
		  const cats = await getData({'function':'categories_list'});
		  setCategories(cats);
		}
	
		const getPackages = async () => {
		  const packs = await getData({'function':'packages_list'});
		  setPackages(packs);
		}

		const getCustomerProducts = async () => {
			const cusProds = await getData({'function':'customer_products_list', 'pars':{'account_id':props.customer}});
			setCustomerProducts(cusProds);
		}
	
		getProducts();
		getCustomerProducts();
	  }, [])

	  function QuickSearchToolbar() {
		return (
			<Box
				sx={{
				p: 0.5,
				pb: 0,
				}}
			>
				<GridToolbarQuickFilter />
			</Box>
		);
	}

	const columns = [
		{
			field: 'id', 
			headerName: 'ID', 
			width: 90,
			hide: true,
		},
		{
			field: 'cat_name',
			headerName: 'კატეგორია',
			width: 180,
			editable: false,
			// type: 'number',
		},
		{
			field: 'product_name',
			headerName: 'სახელი',
			width: 400,
		},
		{
			field: 'fee',
			headerName: 'ღირებულება',
			width: 110,
		},
		{
			field: 'create_time',
			headerName: 'ინსტალაციის თარიღი',
			width: 200,
		},
		{
			field: 'minimal_deposit',
			headerName: 'მინიმალური დეპოზიტი',
			width: 200,
		},
		{
			field: 'status',
			headerName: 'სტატუსი',
			description: 'აქტიური ან არააქტიური',
			width: 100,
			type: 'boolean'
		},
		{
			field: 'action',
			headerName: ' ',
			width: 120,
			renderCell: (params) => (
				<strong>
					<Button 
						variant="contained"
						color="primary"
						size="small"
						style={{ marginLeft: 5 }}
						onClick={() => {
						console.log("Row Parameters: ", params);
						console.log("Row Parameters: account_id:", params.row.id);
						//   props.setCustomer(params.row.account_id);
						// navigate("/customer");
						}}
					>
						დეტალები
					</Button>
				</strong>
			),
		},
	];

	return (
		<div>
			<h3>პროდუქტების ჩამონათვალი</h3>
			<Box sx={{ height: 502, width: 1 }}>
				<DataGrid
					rowHeight={35}
					rows={customerProducts}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					// checkboxSelection
					disableSelectionOnClick
					components={{ Toolbar: QuickSearchToolbar }}
				/>
			</Box>
		</div>
	);
};

export default CustomerProducts;