import React from 'react';

const Settings = () => {
	return (
		<div>
			<h2>Settings</h2>
		</div>
	);
};

export default Settings;