import React, { useState, useEffect } from 'react';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const Commercials = (props) => {

	const [ rows, setRows ] = useState([]);
	const { token, setToken } = useToken();
	const setIsLoading = props.setIsLoading;
	const navigate = useNavigate();
  
	useEffect(() => {
	  async function getData() {
		// console.log('token:', token);
		const pars = { 
			"function":"commercials_list", 
			"token":token, 
			"pars":"",
		}
		setIsLoading(true);
		const ApiGet = await ApiCall(pars);
		setIsLoading(false);
		// console.log('ApiGet:', ApiGet);
		if (ApiGet['success'] == 'success') {
			// console.log('ApiGet is OK');
			const ApiData = ApiGet.data;
			// console.log('ApiData:', ApiData);
			setRows(ApiData);
			}
		}
		getData();
	}, [])

	function QuickSearchToolbar() {
	  return (
		<Box
			sx={{
				p: 0.5,
				pb: 0,
			}}
		>
			<GridToolbarQuickFilter />
		</Box>
	  );
	}

	function representer(params) {
		const fn=(params.row.first_name == null) ? "" : params.row.first_name;
		const ln=(params.row.last_name == null) ? "" : params.row.last_name;
		return (fn + " " + ln);
	}

	const columns = [
		{ 
			field: 'cid', 
			headerName: 'ს/კ', 
			width: 120,
		},
		{
			field: 'lfname',
			headerName: 'სამართლებრივი ფორმა',
			width: 250,
		},
		{
			field: 'company_name',
			headerName: 'სახელწოდება',
			width: 250,
		},
		{
			field: 'address',
			headerName: 'მისამართი',
			width: 300,
		},
		{
			field: 'phone',
			headerName: 'ტელეფონი',
			width: 140,
		},
		{
			field: 'representer',
			headerName: 'წარმომადგენელი',
			width: 140,
			renderCell: (params) => (representer(params)),
		},
		{
			field: 'action',
			headerName: ' ',
			width: 120,
			renderCell: (params) => (
			  <strong>
				<Button 
				  variant="contained"
				  color="primary"
				  size="small"
				  style={{ marginLeft: 5 }}
				  onClick={() => {
					props.setCommercial(params.row);
					navigate("/registry/commercial");
				  }}
				>
				  დეტალები
				</Button>
			  </strong>
			),
		  },
	];

	return(
		<div style={{ height: 650, width: '100%', position: 'absolute' }}>
		  <h2>იურიდიული პირის რეესტრი</h2> 
		  <Button variant="outlined" startIcon={<AddBusinessIcon />}>იურიდიული პირის რეგისტრაცია</Button>&nbsp;&nbsp;
		  <Box sx={{ height: 502, width: 1 }}>
			<DataGrid
				getRowId={(row) => row.cid}
				rowHeight={35}
				rows={rows}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				// checkboxSelection
				disableSelectionOnClick
				components={{ Toolbar: QuickSearchToolbar }}
			/>
		  </Box>
		</div>
	);
};

export default Commercials;