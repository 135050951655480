import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const CustomerIndividual = (props) => {
	const customerDetails = props.customerDetails;

	const { token, setToken } = useToken();
	const [editEnabled, setEditEnabled] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	useEffect(() => {
		async function getData(fn) {
			const pars = {
				"function": fn,
				"token": token,
			}
			const ApiGet = await ApiCall(pars);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				return ApiData;
			}
		}
		getData();
	}, []);

	const activeStatus = [
		{ "id": "0", "name": "გამორთული" },
		{ "id": "1", "name": "ჩართული" }
	];

	const isVerified = [
		{ "id": "0", "name": "არა" },
		{ "id": "1", "name": "კი" }
	];

	const handleEditEnableSave = () => {
		console.log('before editEnable ', editEnabled);
		if (editEnabled) {
			// Save data to DB
			setEditEnabled(false);
		} else {
			setEditEnabled(true);
		}
		console.log('after editEnable ', editEnabled);
	}

	const handleCancel = () => {
		document.getElementById("customer-details").reset();
		// window.location.reload(false);
		setEditEnabled(false);
	}

	const handleDeleteOpen = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(true);
	}

	const handleDeleteClose = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(false);
	}

	const handleDeleteConfirm = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(false);
	}

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("submission prevented");
	}

	return (
		<Box
			component="span"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<div>
				<h3>ფიზიკური პირი</h3>
				<form id="customer-details" onSubmit={onSubmit}>
					<fieldset disabled style={{ background: 'lightcyan' }}>
						<div>
							<TextField
								style={{ width: 150 }}
								label="პირადი ნომერი"
								id="taxid"
								value={customerDetails.rs.pid}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 320 }}
								label="სახელი"
								id="first_name"
								value={customerDetails.rs.first_name}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 332 }}
								label="გვარი"
								id="last_name"
								value={customerDetails.rs.last_name}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 150 }}
								label="დაბადების თარიღი"
								id="birthday"
								value={customerDetails.rs.birthday}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								inputProps={{ readOnly: true }}
								style={{ width: 150 }}
								label="სქესი"
								value={customerDetails.rs.gender == 'm' ? 'მამრობითი' : 'მდედრობითი'}
							/>
						</div>
						<div>
							<TextField
								multiline
								minRows={2}
								maxRows={4}
								label="იურიდიული მისამართი"
								id="address"
								style={{ width: 1167 }}
								fullWidth
								value={customerDetails.rs.address}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</fieldset>
					<fieldset disabled={!editEnabled}>
						<div>
							<TextField
								multiline
								rows={2}
								maxRows={4}
								label="ფაქტიური მისამართი"
								id="address_fact"
								style={{ width: 1167 }}
								fullWidth
								defaultValue={customerDetails.local.address_fact}
							/>
						</div>
						<div>
							<TextField
								style={{ width: 180 }}
								label="სტატუსი"
								select
								disabled={!editEnabled}
								defaultValue={customerDetails.local.status}
								id="status"
							>
								{activeStatus.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</TextField>
							<TextField
								label="მობილური ტელეფონი"
								id="cell_phone"
								style={{ width: 200 }}
								defaultValue={customerDetails.local.cellphone}
							/>
							<TextField
								label="ფიქსირებული ტელეფონი"
								id="phone"
								style={{ width: 200 }}
								defaultValue={customerDetails.local.phone}
							/>
							<TextField
								id="email"
								label="ელ.ფოსტა"
								variant="outlined"
								style={{ width: 200 }}
								defaultValue={customerDetails.local.email}
							/>
						</div>
						<div>
							<TextField
								multiline
								rows={2}
								maxRows={4}
								label="შენიშვნა"
								id="notes"
								style={{ width: 1167 }}
								fullWidth
								defaultValue={customerDetails.local.notes}
							/>
						</div>
					</fieldset>
					<div>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonRemoveAlt1Icon />}
							disabled={editEnabled}
							onClick={handleDeleteOpen}
						>
							კლიენტის გაუქმება
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<HighlightOffIcon />}
							onClick={handleCancel}
							disabled={!editEnabled}
						>
							ცვლილებების უარყოფა
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonAddAlt1Icon />}
							onClick={handleEditEnableSave}
							style={editEnabled ? { backgroundColor: 'red' } : {}}
						>
							{editEnabled ? 'ცვლილებების შენახვა' : 'რედაქტირება'}
						</Button>
					</div>
				</form>
			</div>
			<Dialog
				open={openDeleteDialog}
				onClose={handleDeleteClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						ნამდვილად გსურთ ამ მომხმარებლის გაუქმება?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteClose}>არა</Button>
					<Button onClick={handleDeleteConfirm} autoFocus>დიახ</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default CustomerIndividual;