import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';

const CustomerCommercial = (props) => {
	const customerDetails = props.customerDetails;

	const { token, setToken } = useToken();
	const [legalForms, setLegalForms] = useState([]);
	const [editEnabled, setEditEnabled] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	useEffect(() => {
		async function getLegalForms() {
			const pars = { 
				"function":"legal_forms", 
				"token":token, 
			}
			const ApiGet = await ApiCall(pars);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				console.log('LegalForms:', ApiData);
				setLegalForms(ApiData);
			}
		}
		getLegalForms();
	}, []);

	const activeStatus = [
		{
			"id":"0", 
			"name":"გამორთული"
		},
		{
			"id":"1", 
			"name":"ჩართული"
		}
	];

	const isVerified = [
		{
			"id":"0", 
			"name":"არა"
		},
		{
			"id":"1", 
			"name":"კი"
		}
	];

	const handleEditEnableSave = () => {
		console.log('before editEnable ', editEnabled);
		if (editEnabled) {
			// Save data to DB
			setEditEnabled(false);
		} else {
			setEditEnabled(true);
		}
		console.log('after editEnable ', editEnabled);
	}

	const handleCancel = () => {
		document.getElementById("customer-details").reset();
		// window.location.reload(false);
		setEditEnabled(false);
	}

	const handleDeleteOpen = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(true);
	}

	const handleDeleteClose = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(false);
	}

	const handleDeleteConfirm = () => {
		// alert('ნამდვილად გსურთ ამ მომხმარებლის წაშლა?');
		setOpenDeleteDialog(false);
	}

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("submission prevented");
	}

	return (
		<Box 
			component="span"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<div>
				<h3>იურიდიული პირი</h3>
				<form id="customer-details" onSubmit={onSubmit}>
					<fieldset disabled style={{background:'lightcyan'}}>
						<div>
							<TextField
								color='primary'
								style={{width:180}}
								label="ანგარიშის ნომერი"
								id="account_id"
								value={customerDetails.local.account_id}
								disabled
							/>
							<TextField
								color='secondary'
								style={{width:180}}
								label="საიდინტიფიკაციო კოდი"
								id="taxid"
								value={customerDetails.local.identify}
								disabled
							/>
							<TextField
								color='success'
								style={{width:180}}
								label="სამართლებრივი ფორმა"
								select
								id="company_form"
								value={customerDetails.rs.legal_form}
								disabled
							>
								{legalForms.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.short_name}
									</MenuItem>
								))}
							</TextField>
							<TextField
								color='warning'
								style={{width:580}}
								label="სახელწოდება"
								id="company"
								value={customerDetails.rs.company_name}
								disabled
							/>
						</div>
						<div>
							<TextField
								color='info'
								multiline
								rows={2}
								maxRows={4}
								label="იურიდიული მისამართი"
								id="address"
								style={{width:1167}}
								fullWidth
								value={customerDetails.rs.address}
								disabled
							/>
						</div>
					</fieldset>
					<fieldset disabled={!editEnabled}>
						<div>
							<TextField
								multiline
								rows={2}
								maxRows={4}
								label="ფაქტიური მისამართი"
								id="address_fact"
								style={{width:1167}}
								fullWidth
								defaultValue={customerDetails.rs.address_fact}
							/>
						</div>
						<div>
							<TextField
								style={{width:180}}
								label="სტატუსი"
								select
								disabled={!editEnabled}
								defaultValue={customerDetails.local.status}
								id="status"
							>
								{activeStatus.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</TextField>
							<TextField
								label="ფიქსირებული ტელეფონი"
								id="phone"
								defaultValue={customerDetails.local.phone}
							/>
							<TextField
								label="მობილური ტელეფონი"
								id="cell_phone"
								defaultValue={customerDetails.local.cellphone}
							/>
						</div>
						<div>
							<TextField
								label="წარმომადგენლის თანამდებობა"
								id="representor_position"
								defaultValue={customerDetails.local.rep_position}
							/>
							<TextField
								label="წარმომადგენლის პ/ნ"
								id="representor_pid"
								defaultValue={customerDetails.local.rep_pid}
							/>
							<TextField
								label="სახელი"
								id="first_name"
								defaultValue={customerDetails.local.first_name}
							/>
							<TextField
								label="გვარი"
								id="last_name"
								defaultValue={customerDetails.local.last_name}
							/>
						</div>
						<div>
							<TextField
								multiline
								rows={2}
								maxRows={4}
								label="შენიშვნა"
								id="notes"
								style={{width:910}}
								fullWidth
								defaultValue={customerDetails.local.notes}
							/>
						</div>
					</fieldset>
					<div>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonRemoveAlt1Icon />}
							disabled={editEnabled}
							onClick={handleDeleteOpen}
						>
							კლიენტის გაუქმება
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<HighlightOffIcon />}
							onClick={handleCancel}
							disabled={!editEnabled}
						>
							ცვლილებების უარყოფა
						</Button>&nbsp;&nbsp;
						<Button
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonAddAlt1Icon />}
							onClick={handleEditEnableSave}
							style={ editEnabled ? {backgroundColor:'red'} : {} }
						>
							{ editEnabled ? 'ცვლილებების შენახვა' : 'რედაქტირება' }
						</Button>
					</div>
				</form>
			</div>
			<Dialog
				open={openDeleteDialog}
				onClose={handleDeleteClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						ნამდვილად გსურთ ამ მომხმარებლის გაუქმება?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteClose}>არა</Button>
					<Button onClick={handleDeleteConfirm} autoFocus>დიახ</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default CustomerCommercial;