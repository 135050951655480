import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ka } from "date-fns/locale";
import "./Registry.css";
// import Moment from 'react-moment';
import moment from 'moment';

const RegIndStep2 = (props) => {

	let id = props.customerDetails;
	const setRegisterStep = props.setRegisterStep;
	const setCancel = props.setCancel;
	const setCustomerDetails = props.setCustomerDetails;
	const ipid = props.pid;
	const setCustomerState = props.setCustomerState;
	const customerState = props.customerState;
	const [birthday, setBirthday] = useState();
	const [gender, setGender] = useState();
	const [isEditable, setIsEditable] = useState(false);
	// const [customerState, setCustomerState] = useState(2);
	const [errGender, setErrGender] = useState(false);
	const [errFirstName, setErrFirstName] = useState(false);
	const [errLastName, setErrLastName] = useState(false);
	const [errAddress, setErrAddress] = useState(false);
	const [errBirthday, setErrBirthday] = useState(true);
	// const [errCellPhone, setErrCellPhone] = useState(false);
	// const [errEmail, setErrEmail] = useState(false);

	console.log("info:", id);

	const titleHeader = [
		"გთხოვთ შეიყვანოთ მონაცემები",
		"აღნიშნული ნომრით ნაპოვნია პიროვნება",
		"აღნიშნული პიროვნება უკვე რეგისტრირებულია"
	]

	useEffect(() => {
		// if (id && !id.account_id) { setCustomerState(1); }
		// if (!id) { setCustomerState(0); }
		// if (id) setBirthday(id.birthday);
		if (customerState === 0) setIsEditable(true);
		if (id) setBirthday(new Date(id.birthday)); else setBirthday(new Date());

	}, []);

	useEffect(() => {
		moment(birthday).isValid() ? setErrBirthday(false) : setErrBirthday(true);
	}, [birthday]);

	console.log("Enter to RegIndStep2");
	const [openStep, setOpenStep] = React.useState(true);

	const handleBackStep = () => {
		// setOpenStep(false);
		setRegisterStep(1);
	}

	const handleCloseStep = () => {
		setOpenStep(false);
		setCancel(true);
	};

	const handleSubmitStep = (e) => {
		e.preventDefault();
		let isReady = true;
		const et = e.target;
		// console.log('event:', et);

		if (!et.gender.value) { setErrGender(true); isReady = false; } else setErrGender(false);
		if (et.first_name.value === '') { setErrFirstName(true); isReady = false; } else setErrFirstName(false);
		if (et.last_name.value === '') { setErrLastName(true); isReady = false; } else setErrLastName(false);
		if (et.address.value === '') { setErrAddress(true); isReady = false; } else setErrAddress(false);
		if (!birthday || !moment(birthday).isValid()) { setErrBirthday(true); isReady = false; } else setErrBirthday(false);
		// if (et.cellphone.value < 500000000) { setErrCellPhone(true); isReady = false; } else setErrCellPhone(false);
		// if (et.email.value === '') { setErrEmail(true); isReady = false; } else setErrEmail(false);

		// console.log('BD:', birthday);
		// console.log('BD ISO:', birthday.toISOString());
		// console.log('BD UTC:', birthday.toUTCString());
		// console.log('BD LOC:', birthday.toLocaleDateString());
		// console.log('Moment:', moment(birthday).format().split('T')[0]);

		// console.log('Reday to Submit', isReady);
		if (isReady) {
			const cd = {
				"pid": et.pid.value,
				"first_name": et.first_name.value,
				"last_name": et.last_name.value,
				"birthday": moment(birthday).format().split('T')[0],
				"gender": et.gender.value,
				"address": et.address.value,
				// "cellphone": et.cellphone.value,
				// "email": et.email.value,
			}
			// console.log("newData", cd);
			setCustomerDetails(cd);
			setOpenStep(false);
			setRegisterStep(3);
		}
	};

	const genderList = [
		{ "id": "m", "name": "მამრობითი" },
		{ "id": "f", "name": "მდედრობითი" }
	];

	return (
		<Dialog open={openStep} onClose={handleCloseStep}
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
		><form onSubmit={handleSubmitStep}>
				<DialogTitle>ახალი აბონენტი</DialogTitle>
				<DialogContent>
					<Box>
						<DialogContentText>
							<h4 className="red">{titleHeader[customerState]}</h4>
						</DialogContentText>
						<fieldset disabled={!isEditable}>
							<div>
								<TextField
									id="pid"
									label="პირადი ნომერი"
									variant="outlined"
									style={{ width: 150 }}
									// value={id.pid}
									value={ipid}
								/>
								<TextField
									// value={gender}
									inputProps={{ readOnly: !isEditable }}
									style={{ width: 150 }}
									label="სქესი"
									select
									id="gender"
									name="gender"
									defaultValue={id ? id.gender : ''}
									validate
									error={errGender}
								>
									{genderList.map((option) => (
										<MenuItem key={option.id} value={option.id}>
											{option.name}
										</MenuItem>
									))}
								</TextField>
								<LocalizationProvider id="bdC" dateAdapter={AdapterDateFns} adapterLocale={ka}>
									<DatePicker
										inputFormat="yyyy-MM-dd"
										readOnly={!isEditable}
										id="birthday"
										label="დაბადების თარიღი"
										minDate={new Date("1920-01-01")}
										maxDate={new Date()}
										value={birthday}
										// defaultValue={birthday}
										mask="____-__-__"
										renderInput={(params) => <TextField {...params} style={{ width: 148 }} error={errBirthday} />}
										onChange={(newValue) => {
											// document.getElementById('birthday').value = newValue;
											setBirthday(newValue);
										}}
										error={errBirthday}
									/>
								</LocalizationProvider>
							</div>
							<div>
								<TextField
									label="სახელი"
									id="first_name"
									style={{ width: 480 }}
									defaultValue={id ? id.first_name : ''}
									error={errFirstName}
								/>
							</div>
							<div>
								<TextField
									label="გვარი"
									id="last_name"
									style={{ width: 480 }}
									defaultValue={id ? id.last_name : ''}
									error={errLastName}
								/>
							</div>
							<div>
								<TextField
									label="მისამართი"
									name="address"
									multiline
									rows={2}
									style={{ width: 480 }}
									defaultValue={id ? id.address : ''}
									error={errAddress}
								/>
							</div>
							{/* <div>
								<TextField
									type="number"
									id="cellphone"
									inputProps={{ minLength: 9 }}
									label="მობილური ტელეფონი"
									variant="outlined"
									style={{ width: 200 }}
									defaultValue={id ? id.cellphone : ''}
									error={errCellPhone}
								/>
								<TextField
									id="email"
									label="ელ.ფოსტა"
									variant="outlined"
									style={{ width: 264 }}
									defaultValue={id ? id.email : ''}
									error={errEmail}
								/>
							</div> */}
						</fieldset>
					</Box>
				</DialogContent>
				<DialogActions>

					<Button variant="contained" onClick={handleBackStep}>უკან</Button>
					<Button variant="contained" type="submit" disabled={customerState === 2}>გაგრძელება</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default RegIndStep2;