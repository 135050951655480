import ApiCall from '../ApiCall';
import useToken from './useToken';
import { useEffect } from 'react';

const LoadOperator = (props) => {

	const setOperatorDetails = props.setOperatorDetails;
	const { token, setToken } = useToken();

	const getData = async () => {
		console.log("step 123");
		const pars = { 
			"function":"operator_details", 
			"token":token, 
			"pars":"",
		}
		const ApiGet = await ApiCall(pars);
		if (ApiGet['success'] == 'success') {
			const ApiData = ApiGet.data;
			setOperatorDetails(ApiData);
			console.log("operator details: ", ApiData);
		}
	}
	getData();
};

export default LoadOperator;