import React, { useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import useRetailer from '../../App/useRetailer';
import ConfirmDialog from './ConfirmDialog';

const RegIndStep4 = (props) => {
	const customerDetails = props.customerDetails;
	const setCustomerDetails = props.setCustomerDetails;
	const setRegisterStep = props.setRegisterStep;
	const setCustomerState = props.setCustomerState;
	const customerState = props.customerState;
	const setIsLoading = props.setIsLoading;
	const setCustomer = props.setCustomer;
	const imageFiles = props.imageFiles;

	const { retailer, setRetailer } = useRetailer();
	const [errCellphone, setErrCellphone] = useState(false);
	const [errEmail, setErrEmail] = useState(false);
	const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
	const [customerData, setCustomerData] = useState();

	const onSubmit = (event) => {
		event.preventDefault();
		// console.log('event', event);
		if (event.nativeEvent.submitter.name === 'frm-save') {
			const et = event.target;
			let isReady = true;
			if (et.cellphone.value < 500000000 || et.cellphone.value > 599999999) { isReady = false; setErrCellphone(true); } else setErrCellphone(false);
			if (et.email.value.length < 4) { isReady = false; setErrEmail(true); } else setErrEmail(false);
			if (isReady) {
				const cd = {
					...customerDetails,
					"address_fact": et.address_fact.value,
					"cellphone": et.cellphone.value,
					"email": et.email.value,
					"notes": et.notes.value,
					"imageFiles": imageFiles,
					"retailer": retailer,
				}
				console.log("newData: ", cd);
				setCustomerDetails(cd);
				setCustomerData(cd);
				setOpenVerifyDialog(true);
			}
		}
		if (event.nativeEvent.submitter.name === 'frm-reset') {
			document.getElementById("customer-details").reset();
		}
	}

	const handleBackStep = () => {
		setRegisterStep(3);
	}

	const handleTest = () => {
		console.log('CD: ', customerDetails);
	}
	const handleTest2 = () => {
		const cd = { 'pid': 12345 };
		setCustomerDetails(cd);
		console.log('CD: ', customerDetails);
	}

	return (
		<Box
			component="span"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<div>
				<h3>ფიზიკური პირი</h3>
				<form id="customer-details" onSubmit={onSubmit}>
					<fieldset disabled style={{ background: 'lightcyan' }}>
						<div>
							<TextField
								style={{ width: 150 }}
								label="პირადი ნომერი"
								id="taxid"
								value={customerDetails.pid}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 320 }}
								label="სახელი"
								id="first_name"
								value={customerDetails.first_name}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 332 }}
								label="გვარი"
								id="last_name"
								value={customerDetails.last_name}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								style={{ width: 150 }}
								label="დაბადების თარიღი"
								id="birthday"
								value={customerDetails.birthday}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								inputProps={{ readOnly: true }}
								style={{ width: 150 }}
								label="სქესი"
								value={customerDetails.gender === 'm' ? 'მამრობითი' : 'მდედრობითი'}
							/>
						</div>
						<div>
							<TextField
								multiline
								minRows={2}
								maxRows={4}
								label="იურიდიული მისამართი"
								id="address"
								style={{ width: 1167 }}
								fullWidth
								value={customerDetails.address}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</fieldset>
					<fieldset id='customer-data'>
						<div>
							<TextField
								multiline
								minRows={2}
								maxRows={4}
								label="ფაქტიური მისამართი"
								name="address_fact"
								style={{ width: 1167 }}
								fullWidth
							/>
						</div>
						<div>
							<TextField
								label="მობილური ტელეფონი"
								name="cellphone"
								id="cellphone"
								style={{ width: 200 }}
								inputProps={{ type: "Number" }}
								error={errCellphone}
							/>
							<TextField
								label="ფიქსირებული ტელეფონი"
								name="phone"
								id="phone"
								style={{ width: 200 }}
							/>
							<TextField
								id="email"
								label="ელ.ფოსტა"
								variant="outlined"
								style={{ width: 200 }}
								error={errEmail}
							/>&nbsp;&nbsp;
							<FormControlLabel
								label="აბონენტად რეგისტრაცია"
								control={<Checkbox defaultChecked id="registerAsCustomer" />}
							/>
						</div>
						<div>
							<TextField
								multiline
								minRows={2}
								maxRows={4}
								label="შენიშვნა"
								name="notes"
								style={{ width: 1167 }}
								fullWidth
							// defaultValue={customerDetails.local.notes}
							/>
						</div>
					</fieldset>
					<div>
						<Button
							name="frm-back"
							// type="submit"
							variant="contained"
							color="primary"
							endIcon={<HighlightOffIcon />}
							onClick={handleBackStep}
						>
							უკან
						</Button>&nbsp;&nbsp;
						<Button
							name="frm-save"
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<PersonAddAlt1Icon />}
						// onClick={handleSave}
						>
							დამატება
						</Button>&nbsp;&nbsp;
						{/* <Button
							name="myTest"
							variant="contained"
							color="primary"
							onClick={handleTest}
						>
							TEST
						</Button>&nbsp;&nbsp;
						<Button
							name="myTest2"
							variant="contained"
							color="primary"
							onClick={handleTest2}
						>
							TEST
						</Button> */}
					</div>
				</form>
			</div>
			{openVerifyDialog && <ConfirmDialog
				cd={customerData}
				openDialog={openVerifyDialog}
				setOpenDialog={setOpenVerifyDialog}
				customerDetails={customerDetails}
				setIsLoading={setIsLoading}
				setCustomer={setCustomer}
				setCustomerDetails={setCustomerDetails}
			/>}
		</Box>
	);
};

export default RegIndStep4;