import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Link, useNavigate } from 'react-router-dom';
import useToken from '../App/useToken';
import RetailerSelect from '../ui/RetailerSelect';
import { TextField } from '@mui/material';

// import { Link } from '@mui/material';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

const TopMenu = (props) => {
	const { token, setToken } = useToken();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const operatorDetails = props.operatorDetails;
	const operator_info = operatorDetails.operator_info;
	const individual_info = operatorDetails.individual_info;
	const accounts = operatorDetails.accounts;

	async function handleLogout() {
		setToken('');
		sessionStorage.removeItem('token');
		navigate("/");
		window.location.reload(false);
		console.log("new toktoken:", token);
	}

	return (
		<div>
			<Button
				id="demo-customized-button"
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				disableElevation
				onClick={handleClick}
				variant="outlined"
				endIcon={<KeyboardArrowDownIcon />}
			>
				რეესტრი
			</Button>
			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<Link to="/registry/individuals" style={{ textDecoration: 'none' }}>
					<MenuItem onClick={handleClose} disableRipple>
						<PersonIcon />
						ფიზიკური პირები
					</MenuItem>
				</Link>
				<Link to="/registry/commercials" style={{ textDecoration: 'none' }}>
					<MenuItem onClick={handleClose} disableRipple>
						<CopyrightIcon />
						იურიდიული პირები
					</MenuItem>
				</Link>
			</StyledMenu>
			&nbsp;
			<Link to="/customers" style={{ textDecoration: 'none' }}>
				<Button variant="outlined">
					მომხმარებლები
				</Button>
			</Link>
			&nbsp;
			<Link to="/products" style={{ textDecoration: 'none' }}>
				<Button variant="outlined">
					პროდუქტები
				</Button>
			</Link>
			&nbsp;
			<Link to="/packages" style={{ textDecoration: 'none' }}>
				<Button variant="outlined">
					პაკეტები
				</Button>
			</Link>
			&nbsp;
			<Link to="/settings" style={{ textDecoration: 'none' }}>
				<Button variant="outlined">
					პარამეტრები
				</Button>
			</Link>
			&nbsp;
			<Button 
				variant="outlined" 
				sx={{
					backgroundColor: 'lightyellow',
				}}
			>
				{individual_info.first_name} {individual_info.last_name}
			</Button>
			&nbsp;
			<RetailerSelect title={'არჩეული კომპანია'} />
			&nbsp;
			<Link to="/" style={{ textDecoration: 'none' }}>
				<Button 
					variant="outlined"
					onClick={ handleLogout }
					>
					გასვლა
				</Button>
			</Link>
		</div>
	);
};

export default TopMenu;