import React, { useState, useEffect } from 'react';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const Individuals = (props) => {

	const [ rows, setRows ] = useState([]);
	const { token, setToken } = useToken();
	const setIsLoading = props.setIsLoading;
	const navigate = useNavigate();

	useEffect(() => {
		async function getData() {
			// console.log('token:', token);
			const pars = { 
			"function":"individuals_list", 
			"token":token, 
			"pars":"",
			}
			setIsLoading(true);
			const ApiGet = await ApiCall(pars);
			setIsLoading(false);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] == 'success') {
			// console.log('ApiGet is OK');
			const ApiData = ApiGet.data;
			// console.log('ApiData:', ApiData);
			setRows(ApiData);
			} else {
				navigate("/login");
			}
		}
		getData();
	}, [])

	function QuickSearchToolbar() {
	return (
		<Box
		sx={{
			p: 0.5,
			pb: 0,
		}}
		>
		<GridToolbarQuickFilter />
		</Box>
	);
	}

	const columns = [
		{
			field: 'pid',
			headerName: 'პირადი N',
			width: 140,
			editable: false,
			// type: 'number',
		},
		{
			field: 'first_name',
			headerName: 'სახელი',
			width: 160,
		},
		{
			field: 'last_name',
			headerName: 'გვარი',
			width: 160,
		},
		{
			field: 'gender',
			headerName: 'სქესი',
			width: 100,
			renderCell: (params) => (
				(params.row.gender === 'm') ? 'კაცი' : (params.row.gender === 'f') ? 'ქალი' : ''
			),
		},
		{
			field: 'birthday',
			headerName: 'დაბადების თარიღი',
			width: 160,
		},
		{
			field: 'address',
			headerName: 'მისამართი',
			width: 300,
		},
		{
			field: 'phone',
			headerName: 'ტელეფონი',
			width: 140,
		},
		{
			field: 'action',
			headerName: ' ',
			width: 120,
			renderCell: (params) => (
			  <strong>
				<Button 
				  variant="contained"
				  color="primary"
				  size="small"
				  style={{ marginLeft: 5 }}
				  onClick={() => {
					props.setIndividual(params.row);
					navigate("/registry/individual");
				  }}
				>
				  დეტალები
				</Button>
			  </strong>
			),
		  },
	];

	return(
		<div style={{ height: 650, width: '100%', position: 'absolute' }}>
			<h2>ფიზიკური პირის რეესტრი</h2> 
			<Button variant="outlined" startIcon={<PersonAddIcon />}>ფიზიკური პირის დამატება</Button>&nbsp;&nbsp;
			<Box sx={{ height: 502, width: 1 }}>
			<DataGrid
				getRowId={(row) => row.pid}
				rowHeight={35}
				rows={rows}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				// checkboxSelection
				disableSelectionOnClick
				components={{ Toolbar: QuickSearchToolbar }}
			/>
			</Box>
		</div>
	);
};

export default Individuals;