const apiUrl = 'https://ipsg.ge/proxy/userapi.php';

async function ApiCall(props) {
	// setIsLoading(true);
	const answ = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(props)
	}).then(function(data) {
		// setIsLoading(false);
		return data.json();
	})
	if (answ.success === "fail" && answ.reason === "bad token") {
		sessionStorage.removeItem('token');
		window.location.href = ("/");
		return null;
	}
	return answ;
}

export default ApiCall;