import React, { Suspense, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from '../ui/Home';
import Customers from '../ui/Customer/Customers';
import Products from '../ui/Products/Products';
import useToken from './useToken';
import useRetailer from './useRetailer';
import Customer from '../ui/Customer/Customer';
import TopMenu from '../Menu/TopMenu';
import Individuals from './../ui/Registry/Individuals';
import Commercials from './../ui/Registry/Commercials';
import Individual from './../ui/Registry/Individual';
import Commercial from './../ui/Registry/Commercial';
import Settings from '../ui/Settings';
import SignIn from '../ui/SignIn';
import Waiting from '../ApiCall/Waiting';
import CustomerAddCommercials from './../ui/Registry/CustomerAddCommercials';
import CustomerAddIndividuals from './../ui/Registry/CustomerAddIndividuals';
import CommercialsAddStep1 from './../ui/Registry/CommercialsAddStep1';
import RetailerSelect from '../ui/RetailerSelect';
import WelcomeScreen from '../ui/WelcomeScreen';
import ApiCall from '../ApiCall';
import LoadOperator from './LoadOperator';
import Packages from './../ui/Products/Packages';
import DrawerMenu from '../Menu/DrawerMenu';
import IndividualsList from '../ui/Customer/Individuals';
import CommercialsList from '../ui/Customer/Commercials';
import RegisterIndividuals from '../ui/Registry/RegisterIndividuals';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

function App() {
	const [ isLoading, setIsLoading ] = useState(false);
	const { token, setToken } = useToken();
	const [ customer, setCustomer ] = useState(0);
	const [ individual, setIndividual ] = useState([]);
	const [ commercial, setCommercial ] = useState([]);
	// const [ operatorAccount, setOperatorAccount ] = useState();
	const { retailer, setRetailer } = useRetailer();
	const [ operatorDetails, setOperatorDetails ] = useState([]);

	console.log('L1 token:', token);
	console.log('L1 retailer:', retailer);

	if(!token) {
		return <SignIn setToken={setToken} />
	}

	if (operatorDetails.length === 0) {
		console.log("Needs to load operator details");
		return <LoadOperator setOperatorDetails={setOperatorDetails} />
	}

	if (!retailer ) {
		console.log("let's load welcome screen", operatorDetails);
		return <WelcomeScreen operatorDetails={operatorDetails} />
	}

	const container = (
		<Suspense fallback={<div>Loading...</div>}>
			<Routes>
				<Route path="/" element={ <Home /> } />
				<Route path="/customers" element={ <Customers setCustomer={setCustomer} /> } />
				<Route path="/customers-individuals" element={ <IndividualsList setCustomer={setCustomer} setIsLoading={setIsLoading} /> } />
				<Route path="/customers-commercials" element={ <CommercialsList setCustomer={setCustomer} setIsLoading={setIsLoading} /> } />
				<Route path="/products" element={ <Products setIsLoading={setIsLoading} /> } />
				<Route path="/packages" element={ <Packages setIsLoading={setIsLoading} /> } />
				<Route path="/settings" element={ <Settings setIsLoading={setIsLoading} /> } />
				<Route path="/customer" element={ <Customer customer={customer} setIsLoading={setIsLoading} /> } />
				<Route path="/registry-individuals" element={ <Individuals setIndividual={setIndividual} setIsLoading={setIsLoading} /> } />
				<Route path="/registry-commercials" element={ <Commercials setCommercial={setCommercial} setIsLoading={setIsLoading} /> } />
				<Route path="/registry-individual" element={ <Individual individual={individual} setIsLoading={setIsLoading} /> } />
				<Route path="/registry-commercial" element={ <Commercial commercial={commercial} setIsLoading={setIsLoading} /> } />
				{/* <Route path="/registry/customeraddindividuals" element={ <RegisterIndividuals setIsLoading={setIsLoading} /> } />
				<Route path="/registry/customeraddcommercials" element={ <CustomerAddCommercials setIsLoading={setIsLoading} /> } /> */}
				<Route path="/register-individuals" element={ <RegisterIndividuals setIsLoading={setIsLoading} setCustomer={setCustomer} /> } />
				<Route path="/register-commercials" element={ <CommercialsAddStep1 setIsLoading={setIsLoading} setCustomer={setCustomer} /> } />
			</Routes>
		</Suspense>
	)

	console.log("app retailer", retailer);

	if (retailer && token) {
		return (
			<div>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
					open={isLoading}
					// onClick={handleClose}
				>
					<CircularProgress size={100} />
				</Backdrop>
				{ token ? (<DrawerMenu setToken={setToken} operatorDetails={operatorDetails} container={container} />) : (<></>) }
			</div>
		)
	}

	return (
		<div>
		</div>
	);
}

export default App;