import React, { useState, useEffect } from 'react';
import './RetailerSelect.css';
import ApiCall from '../ApiCall';
import useToken from '../App/useToken';
import useRetailer from '../App/useRetailer';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/Select';

const RetailerSelect = (props) => {
	const { token, setToken } = useToken();
	// const [isLoaded, setIsLoaded] = useState(false);
	const [managedAccounts, setManagedAccounts] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState();
	const { retailer, setRetailer } = useRetailer();

	useEffect(() => {
		async function getData() {
			// console.log('token:', token);
			const pars = {
				"function": "list_operator_accounts",
				"token": token,
				"pars": "",
			}
			// setIsLoading(true);
			const ApiGet = await ApiCall(pars);
			// setIsLoading(false);
			// console.log('ApiGet:', ApiGet);
			if (ApiGet['success'] === 'success') {
				// console.log('ApiGet is OK');
				const ApiData = ApiGet.data;
				// console.log('ApiData:', ApiData);
				setManagedAccounts(ApiData);
				// setAccount(ApiData[0].account_id);
				// console.log('defAccount: ', ApiData[0].account_id);
			} else {
				// navigate("/login");
			}
		}
		getData();
	}, [])

	const handleChangeAccount = (event) => {
		setRetailer(event.target.value);
		window.location.reload(false);
		// console.log('eeee: ', event.target.value);
	}

	const label = (props.ttl) ? props.ttl : 'ორგანიზაცია';
	// console.log('Label: ', label);

	return (
		<TextField
			label={label}
			sx={{
				// m: 1, 
				minWidth: 300,
				backgroundColor: 'steelblue',
				color: 'yellow',
			}}
			id='accSelect'
			select
			value={retailer}
			onChange={handleChangeAccount}
			size='small'
		>
			{managedAccounts.map((option) => (
				<MenuItem
					key={option.account_id}
					value={option.account_id}
				>
					{option.account_name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default RetailerSelect;