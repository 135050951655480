import React from 'react';
import RetailerSelect from './RetailerSelect';
import './WelcomeScreen.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fontSize } from '@mui/system';

const WelcomeScreen = (props) => {
	const operatorDetails = props.operatorDetails;
	const operator_info = operatorDetails.operator_info;
	const individual_info = operatorDetails.individual_info;
	const accounts = operatorDetails.accounts;

	console.log("info od: ", operatorDetails);
	console.log("info in: ", individual_info)

	const bull = "";

	return (
		<div className='Welcome'>
			<header className='Welcome-header'>
				<p>მოგესალმებით {individual_info.first_name} {individual_info.last_name}</p>
				<p>თქვენი ბოლო შემოსვლა: {operator_info.time_login}</p>
				<p style={{fontSize:'medium'}}>გთხოვთ აირჩიოთ ორგანიზაცია:</p>
				<RetailerSelect accounts={accounts} label={'გთხოვთ აირჩიოთ ორგანიზაცია'} />
			</header>

		</div>
	);
};

export default WelcomeScreen;