import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useRetailer from '../../App/useRetailer';
import { AppBar, Toolbar, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

const IndividualsList = (props) => {

	const [rows, setRows] = useState([]);
	const { token, setToken } = useToken();
	const { retailer, setRetailer } = useRetailer();
	const navigate = useNavigate();
	const setIsLoading = props.setIsLoading;

	async function getAccounts() {
		console.log('GetAccounts Fired');
		// const para = {
		//   "account_id":retailer
		// }
		setIsLoading(true);
		const pars = {
			"function": "customers_list",
			"token": token,
			"pars": { "retailer": retailer, "is_commercial": "false" },
		}
		const ApiGet = await ApiCall(pars);
		setIsLoading(false);
		// console.log('ApiGet:', ApiGet);
		if (ApiGet['success'] === 'success') {
			// console.log('ApiGet is OK');
			const ApiData = ApiGet.data;
			// console.log('ApiData:', ApiData);
			setRows(ApiData);
		}
	}

	useEffect(() => {
		getAccounts();
	}, [])

	function QuickSearchToolbar() {
		return (
			<Box
				sx={{
					p: 0.5,
					pb: 0,
				}}
			>
				<GridToolbarQuickFilter />
			</Box>
		);
	}

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 90,
			hide: true,
		},
		{
			field: 'identify',
			headerName: 'პ/ნ',
			width: 120,
			editable: false,
			// type: 'number',
		},
		{
			field: 'first_name',
			headerName: 'სახელი',
			width: 150,
		},
		{
			field: 'last_name',
			headerName: 'გვარი',
			width: 150,
		},
		{
			field: 'status',
			headerName: 'სტატუსი',
			description: 'აქტიური ან არააქტიური',
			width: 100,
			type: 'boolean'
		},
		{
			field: 'action',
			headerName: ' ',
			width: 120,
			renderCell: (params) => (
				<strong>
					<Button
						variant="contained"
						color="primary"
						size="small"
						style={{ marginLeft: 5 }}
						onClick={() => {
							console.log("Row Parameters: ", params);
							console.log("Row Parameters: account_id:", params.row.account_id);
							props.setCustomer(params.row.account_id);
							navigate("/customer");
						}}
					>
						დეტალები
					</Button>
				</strong>
			),
		},
	];

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar position="static" color="transparent">
					<Toolbar>
						<Typography component="div" sx={{ flexGrow: 1 }}>
							აბონენტები, ფიზიკური პირების სია
						</Typography>
						<Button
							variant="outlined"
							color="inherit"
							startIcon={<PersonAddIcon />}
							onClick={() => navigate("/register-individuals")}
						>
							აბონენტის რეგისტრაცია
						</Button>&nbsp;&nbsp;
						<Button
							variant="outlined"
							color="inherit"
							startIcon={<CachedIcon />}
						// onclick={() => getAccounts()}
						>
							განახლება
						</Button>
					</Toolbar>
				</AppBar>
			</Box>
			<Box sx={{ height: 502, width: 1 }}>
				<DataGrid
					rowHeight={35}
					rows={rows}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					disableSelectionOnClick
					components={{ Toolbar: QuickSearchToolbar }}
				/>
			</Box>
		</div >
	);
}

export default IndividualsList;