import { useState } from 'react';

export default function useRetailer() {
	const getRetailer = () => {
		const retailerString = sessionStorage.getItem('retailer');
		const userRetailer = JSON.parse(retailerString);
		return userRetailer
	};

	const [retailer, setRetailer] = useState(getRetailer());

	const saveRetailer = userRetailer => {
		// console.log("acc: ", userRetailer);
		sessionStorage.setItem('retailer', JSON.stringify(userRetailer));
		setRetailer(userRetailer);
	};

	return {
		setRetailer: 
			saveRetailer,
			retailer
	}
}