import React, { useMemo, useState, useCallback } from 'react';
import Webcam from "react-webcam";

import Button from '@mui/material/Button';

const ImageCapture = () => {

	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: "user"
	};

	const config = useMemo(() => ({ video: true }), []);

	return (
		<div>
			<Webcam
				audio={false}
				height={400}
				screenshotFormat="image/jpeg"
				width={540}
				videoConstraints={videoConstraints}
			>
				{({ getScreenshot }) => (
					<Button
						variant="contained"
						onClick={() => {
							const imageSrc = getScreenshot()
						}}
					>
						გადაღება
					</Button>
				)}
			</Webcam>
		</div>
	);
};

export default ImageCapture;