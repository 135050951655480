import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ka } from "date-fns/locale";
import ApiCall from '../../ApiCall';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const CommercialsAddStep1 = (props) => {

	const navigate = useNavigate();
	const { token, setToken } = useToken();
	const setIsLoading = props.setIsLoading;

	const [openStep1, setOpenStep1] = React.useState(true);
	const [openStep2, setOpenStep2] = React.useState(false);
	const [id, setId] = React.useState([]);

	const gender = [
		{
			"id": "m",
			"name": "კაცი"
		},
		{
			"id": "f",
			"name": "ქალი"
		}
	];

	async function getData(pid) {
		// console.log('token:', token);
		const pars = {
			"function": "individual_details",
			"token": token,
			"pars": { "pid": pid },
		}
		setIsLoading(true);
		const ApiGet = await ApiCall(pars);
		setIsLoading(false);
		// console.log('ApiGet:', ApiGet);
		if (ApiGet['success'] == 'success') {
			// console.log('ApiGet is OK');
			const ApiData = ApiGet.data;
			// console.log('ApiData:', ApiData);
			return (ApiData);
		} else {
			// navigate("/login");
		}
	}

	const handleClickOpenStep1 = () => {
		setOpenStep1(true);
	};

	const handleCloseStep1 = () => {
		setOpenStep1(false);
	};

	const handleSubmitStep1 = async () => {
		setOpenStep1(false);
		const pid = document.getElementById('pid').value;
		const individualDetails = await getData(pid);
		setId(individualDetails);
		setOpenStep2(true);
	};

	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSubmitStep1();
		}
	}

	return (
		<div>
			<Dialog open={openStep1} onClose={handleCloseStep1}>
				<DialogTitle>ახალი მომხმარებლის დამატება</DialogTitle>
				<DialogContent>
					<DialogContentText>
					</DialogContentText>
					<TextField
						id="pid"
						label="პირადი ნომერი"
						style={{ width: 300 }}
						autoFocus
						margin="dense"
						fullWidth
						variant="standard"
						onKeyPress={handleKeyPress}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseStep1}>უარყოფა</Button>
					<Button variant="contained" onClick={handleSubmitStep1}>გაგრძელება</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CommercialsAddStep1;