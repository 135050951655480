import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RegIndStep1 from './RegIndStep1';
import RegIndStep2 from './RegIndStep2';
import RegIndStep3 from './RegIndStep3';
import RegIndStep4 from './RegIndStep4';

const RegisterIndividuals = (props) => {
	const setIsLoading = props.setIsLoading;
	const [registerStep, setRegisterStep] = useState(1);
	const [cancel, setCancel] = useState(false);
	const [stepContent, setStepContent] = useState();
	const [customerDetails, setCustomerDetails] = useState([]);
	const [customerState, setCustomerState] = useState(2);
	const [imageFiles, setImageFiles] = useState([]);
	const setCustomer = props.setCustomer;

	const [pid, setPid] = useState("");

	const navigate = useNavigate();

	useEffect(() => {

		console.log("Base Request Step: ", registerStep);

		if (registerStep === 1) setStepContent(
			<RegIndStep1
				setRegisterStep={setRegisterStep}
				setCustomerDetails={setCustomerDetails}
				setCancel={setCancel}
				pid={pid}
				setPid={setPid}
				customerState={customerState}
				setCustomerState={setCustomerState}
			/>);
		if (registerStep === 2) setStepContent(
			<RegIndStep2
				setRegisterStep={setRegisterStep}
				setCustomerDetails={setCustomerDetails}
				customerDetails={customerDetails}
				setCancel={setCancel}
				pid={pid}
				setPid={setPid}
				customerState={customerState}
				setCustomerState={setCustomerState}
			/>);

		if (registerStep === 3) setStepContent(
			<RegIndStep3
				setRegisterStep={setRegisterStep}
				setCustomerDetails={setCustomerDetails}
				customerDetails={customerDetails}
				setCancel={setCancel}
				pid={pid}
				setPid={setPid}
				customerState={customerState}
				setCustomerState={setCustomerState}
				setIsLoading={setIsLoading}
				setCustomer={setCustomer}
				setImageFiles={setImageFiles}
				imageFiles={imageFiles}
			/>);

		if (registerStep === 4) setStepContent(
			<RegIndStep4
				setRegisterStep={setRegisterStep}
				setCustomerDetails={setCustomerDetails}
				customerDetails={customerDetails}
				setCancel={setCancel}
				pid={pid}
				setPid={setPid}
				customerState={customerState}
				setCustomerState={setCustomerState}
				setIsLoading={setIsLoading}
				setCustomer={setCustomer}
				setImageFiles={setImageFiles}
				imageFiles={imageFiles}
			/>);

	}, [registerStep]);

	useEffect(() => {
		if (cancel) navigate('/customers-individuals');
	}, [cancel]);

	return (
		<div>
			{stepContent}
		</div>
	);
};

export default RegisterIndividuals;