import React from 'react';

const Packages = () => {
	return (
		<div>
			პაკეტები
		</div>
	);
};

export default Packages;